.postRow {
    display: grid;
    grid-template-columns: 1fr 100px 80px;
    gap: 8px;
    align-items: center;
}

.postPreview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.postProgress {
    margin-bottom: 0;
}

.postTotal {
    text-align: center;
}

.filterTopItem {
    width: calc((100% - 20px) / 3);
}

.topFilters {
    margin-bottom: 16px;
}

.bottomButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.postFile {
    margin-bottom: 16px;
    position: relative;
    .close {
        position: absolute;
        z-index: 100;
        top: 4px;
        right: 4px;
    }
    .image {
        border-radius: 8px;
        z-index: 0;
    }
    .file {
        height: 40px;
        background-color: #f0f0f0;
        border-radius: 8px;
        padding: 10px;
    }
}

.postMessage, .sendBlock, .bottomButtons {
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
}

.clientsTabel {
    margin: 16px 8px;
}

.steps {
    margin-bottom: 16px !important;
}

.postingWrap {
    min-width: 240px;
    max-width: 700px;
    margin: auto;
    padding: 0 10px;
}

.tableRow {
    td {
        white-space: nowrap;
    }
}