//Header

.header {
  margin-bottom: 10px;
  background: var(--color-primary);
  color: var(--color-white);
}

.content {
  min-width: 240px;
  max-width: 700px;
  height: 44px;
  margin: auto;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .menuButton {
      color: var(--color-white);
    }

    a {
      display: flex;
      width: fit-content;
      height: fit-content;
      .logo {
        width: 120px;
        height: 32px;
        margin-left: 8px;
        margin-right: 8px;
      }
    }

  }

  .right {
    display: flex;
    align-items: center;
    .name {
      font-size: var(--size-caption);
    }
    .avatar {
      margin-left: 8px;
    }
  }

}

