//@import "./custom_antd.css";

@import "global";

@import "../pages/Public/public.module";

@import "./reset";

// FontSizes

$size-body: 16px;
$size-caption: 12px;
$size-title: 20px;

$weight-regular: 400;
$weight-bold: 700;

.color-danger {
  color: $color-danger;
}
.color-gray-3 {
  color: $color-gray-3;
}
.color-primary {
  color: $color-primary;
}
.color-success {
  color: $color-success;
}
.color-warning {
  color: $color-warning;
}


.buttonContactPhone {
  background-color: #2F80ED;
  border-color: #2F80ED;
}
.buttonContactMail {
  background-color: #2D9CDB ;
  border-color: #2D9CDB ;
}
.buttonContactWhatsapp {
  background-color: #25d366;
  border-color: #25d366;
}
.buttonContactTelegram {
  background-color: #59AEDE;
  border-color: #59AEDE;
}
//
//a {
//  color: #B2F900
//}

// ANTD TWEAK
.ant-divider-inner-text {
  font-size: $size-caption;
  font-weight: $weight-regular;
  color: $color-gray-3;
}
.ant-dropdown-menu-item {
  padding: 10px 20px;
}
.ant-btn-link {
  padding-left: 0;
  padding-right: 0;
}
.ant-dropdown-menu-item-group {
  padding: 10px;
}
.ant-carousel .slick-dots li button {
  background-color: $color-primary;
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: $color-primary;
}
.ant-modal-body {
  padding: 0;
}
.ant-select-item-option-content {
  padding: 4px 0;
}
.ant-badge {
  font-size: inherit;
}
.ant-badge-status-text {
  font-size: inherit;
}
.ant-btn-primary {
  color: $color-white;
}

// App

.dissolved {
  animation: show-element 1 300ms ease-out;
}

@keyframes show-element {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.block {
  width: 100%;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: $color-gray-hover;
}

.deleted {
  opacity: 0.3;
}

.align-right {
  text-align: right;
}


//Fonts

body, html {
  background: $color-white;
  font-family: 'Roboto', sans-serif;
  font-size: $size-body;
  font-weight: $weight-regular;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 320px;
}

.body {
  font-size: $size-body;
}
.bold {
  font-weight: $weight-bold;
}

.caption {
  font-size: $size-caption;
  font-weight: $weight-regular;
}

h3 {
  font-size: $size-title;
  line-height: 32px;
  margin: 0;
}


/*Login Page*/

.crm-login-logo {
  padding-top: 40px;
  /*margin-top: 40px;*/
  margin-bottom: 40px;
  text-align: center;

  .App-logo {
    width: 100%;
  }
}

.crm-login-layout {
  min-width: 240px;
  max-width: 320px;
  margin: auto;
  padding: 0 20px;
  text-align: center;
}

//Not Found

.crm-not-found-layout {
  min-width: 240px;
  max-width: 510px;
  margin: auto;
  padding: 0 20px;
  min-height: 100vh;
  padding-top: 100px;

  h1 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 16px;
  }
}

//// Payment
//.crm-payment-layout {
//  min-width: 240px;
//  max-width: 400px;
//  margin: auto;
//  padding: 40px 20px;
//  text-align: center;
//}
//.crm-payment-logo {
//  margin-bottom: 20px;
//  height: 60px;
//  img {
//    height: 100%;
//  }
//}
//.payment-logo {
//  height: 60px;
//  img {
//    height: 100%;
//  }
//}
//.crm-payment-icon {
//  font-size: 60px;
//}
//.button-yellow {
//  background-color: #FDB913;
//  color: #000;
//  border-color: #EAA90C;
//
//}
//.button-yellow:hover {
//  background-color: #EAA90C;
//  color: #000;
//  border-color: #EAA90C;
//}
//.button-yellow:visited {
//  background-color: #FDB913;
//  color: #000;
//  border-color: #EAA90C;
//}
//.crm-payment-text {
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  padding-top: 10px;
//}

/*Default Layout*/

.crm-header {
  width: 100%;
}

.crm-layout {
  min-width: 240px;
  max-width: 700px;
  margin: auto;
  padding: 0 10px;
}
.crm-layout-content {
  padding-bottom: 80px;
}

.crm-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.space {
  margin: 10px;
}

// Title

.title {
  font-size: $size-title;
}

.crm-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  gap: 10px;
  line-height: 40px;
  font-size: $size-title;
  font-weight: 400;
}
.crm-title-back {
  width: 64px;
  text-align: left;
}
.crm-title-headline {
  width: calc(100% - 148px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  text-align: center;
}
.crm-title-action {
  width: 64px;
  text-align: right;
}
//.crm-title-left {
//  display: flex;
//  gap: 10px
//}
//.crm-title-left::before {
//  content: "→";
//}


.title {
  font-weight: 700;
}

.crm-title-divider {
  color: $color-gray-5;
}

.crm-title-crop {
  flex-grow: 2;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Filters
.crm-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  .crm-filters-2-item {
    width: calc(50% - 5px);
  }

}
.crm-filters-3-item {
  width: calc((100% - 20px) / 3);
}
.crm-filters-2-item {
  width: calc((100% - 0px) / 2);
}
.crm-filters-1-item {
  width: 100%;
}


// Spin
.crm-window-spin {
  position: absolute;
  top: 50%;
  left: 50%;
}


// Card

.crm-card {
  background: $color-solid;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.crm-card-content {
  width: 100%;
  padding: 16px;
}
.crm-card-action {
  border-top: 1px solid $color-gray-5;
  padding: 10px 20px;
}
.crm-card-form-line {
  display: flex;
  justify-content: space-between;
}
.crm-card-input {
  width: 70%;
}

// Message
.crm-message {
  border: 1px solid $color-border;
  border-radius: 4px;
  padding: 10px;
}
.crm-message-text {
  font-size: $size-body;
  white-space: pre-wrap;
}
.crm-message.done {border-color: $color-success;}
.crm-message.danger {border-color: $color-danger;}
.crm-message.warning {border-color: $color-warning;}
.crm-message-file {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid $color-border;
}
.crm-message-file-icon {
  width: 30px;
  height: 36px;
}
.crm-message-file span {
  line-height: 36px;
}


// Upload File
.upload-file {
  display: flex;
}


// Modal
.react-modal-sheet-content {
  //padding: 0 20px;
}
.crm-modal-sheet-title {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.crm-modal-sheet-title-text {
  line-height: 32px;
  font-size: $size-title;
  font-weight: $weight-bold;
}
.crm-modal-sheet-action {
  padding: 20px;
}

.crm-modal-select-color {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.crm-modal-select-color-form {
  padding: 0 20px 20px 20px;
}

.modal-selected-users-container {
  display: block;
  overflow: auto;
  width: 100%;
  margin: 10px 0;
}
.modal-selected-users {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  width: fit-content;
  margin: 20px;
}
.modal-selected-user {
  width: 70px;
  text-align: center;
}
.modal-selected-user-text {
  width: inherit;
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis;
  line-height: 18px;
}
.modal-selected-user-avatar {
  position: relative;
}
.modal-selected-user-close {
  position: absolute;
  top: -5px;
  right: 0;
}
.modal-selected-user-list {
  padding: 0 20px 20px 20px;
}
.modal-selected-user-item {
  display: block;
  padding: 10px 20px;
  border-bottom: 1px solid $color-border;
  cursor: pointer;
  transition: background-color 300ms ease-out;
  transition: color 300ms ease-out;
}
.modal-selected-user-item:hover {
  background-color: $color-gray-hover;
  color: $color-gray-3;
}
.modal-selected-user-item.selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-gray-5;
}
.modal-selected-users-rows {
  margin-top: 10px;
  height: 45vh;
  overflow: auto;
}

.modal-basic-form {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: 20px;

  padding: 20px;
  @media screen and (min-width: 425px) {
      padding: 0;
  }

}
.highlight {
  font-size: 40px;
}


// Display Data
.crm-display-data {
  font-size: $size-caption;
}
.crm-display-data-key {
  color: $color-gray-3;
}
.crm-display-data-value {
  font-size: $size-body;
}

// CHILDREN

.client-list-row {
  display: block;
  cursor: pointer;
  background: $color-solid;
  border-radius: 8px;
  transition: background-color 300ms ease-out;
  padding: 8px;
}

.children-list-row {
  display: block;
  padding: 8px;
  cursor: pointer;
  transition: background-color 300ms ease-out;
  background: $color-solid;
  border-radius: 3px;
  margin-bottom: 10px;

  &:hover {
    background: $color-gray-hover;
  }
}
.client-list-row.disabled {
  //opacity: 0.5;
  color: $color-gray-3;
}
.client-list-row:hover {
  background-color: $color-gray-hover;
}

.children-message-title {
  display: flex;
  justify-content: space-between;
}
.client-form-message {
  display: flex;
  gap: 10px;
}
.client-form-message-input {
  flex-grow: 2;
}
.client-form-message-file {
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis;
  background-color: $color-gray-6;
  padding: 6px;
  margin-top: 10px;
  border-radius: 4px;
}


.crm-indicator {
  border: 1px solid $color-border;
  border-radius: 4px;
  padding: 10px;
  background-color: $color-gray-hover;
}
.crm-indicator-title {
  display: flex;
  justify-content: space-between;
}
.crm-indicator-result {
  display: flex;
  gap: 10px;
}
.crm-client-graph {
  padding-bottom: 40px;
}


.crm-contact {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}


// Forms

.form-contact {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.form-contact-select {
  width: calc( (100% - 20px) / 3 );
}
.form-contact-input {
  width: calc( ((100% - 20px) * 2 / 3) - 40px );
}
.form-masked-input {
  background: transparent;
  border: 1px solid #424242;
  color: #fff;

  &:focus {
    border: 1px solid #b7e827;
  }
  &:hover {
    border: 1px solid #b7e827;
  }
}

// CALENDAR

.calendar-layout {
  min-width: 240px;
  margin: auto;
  padding: 0 10px;
}

.calendar {
  width: 100%;
  min-height: calc(100vh - 120px);
  overflow: scroll;
  font-size: 9px;
}
.crm-calendar-header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
}
.crm-calendar-header-right {
  display: flex;
  gap: 5px;
  align-items: center;
}
.crm-calendar-select-week {
  //width: 228px;
  display: flex;
  justify-content: space-between;
  //gap: 10px;
  margin: 0 auto 10px auto;
  line-height: 24px;
}

.calendar-table {
  //border-collapse: collapse;
  border-spacing: 10px 0;
  //border-spacing: 0;
  height: 20px;
}
.calendar td {
  //padding: 5px;
  //border: 1px solid red;
}
.calendar-head-date {
  text-align: center;
  text-transform: uppercase;
  padding: 0;
}
.calendar-head-time {
  text-align: right;
  text-transform: uppercase;
}
.calendar-head-date-number {
  font-size: $size-caption;
  background-color: $color-solid;
  text-align: center;
  border-radius: 8px;
  text-transform: uppercase;
  color: $color-gray-2;
}
.calendar-body {
  display: block;
  height: calc(100vh - 120px);
  overflow: scroll;
}
.calendar-head-date-number.today {
  color: $color-primary;
}
.calendar-caption {
  display: inline-grid;
  font-size: 9px;
  text-align: right;
}
.calendar hr {
  border: 1px solid $color-gray-6;
}
.calendar-training {
  width: inherit;
  height: 44px;
  font-size: $size-caption;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 4px;
  color: var(--color-black);
}
.calendar-training-user {
  display: inline;
  padding: 0 3px;
  border-radius: 6px;
  margin-right: 4px;
}
//.calendar-training:not(:first-child) {
//  margin-top: 4px;
//}
.calendar-training:hover {
  color: $color-gray-3;
}
.calendar-training div {
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis;
}
.calendar-training.task {
  border: 1px solid $color-gray-5;
  box-sizing: border-box;

  div {
    color: var(--color-black);
  }
}
.calendar-training.task.done {border-color: $color-success;}
.calendar-training.task.danger {border-color: $color-danger;}
.calendar-training.task.warning {border-color: $color-warning;}

.calendar-row {
  height: 46px;
  vertical-align: top;
}
.calendar-row td {
  padding: 0;
}
.calendar-row td:hover {
}
.calendar-add-button {
  height: 46px;
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--color-white);
  color: $color-white;
  text-align: center;
  line-height: 46px;
  transition: 0.2s;

  span {
    color: transparent;
  }
}
.calendar-add-button:hover {
  background-color: $color-gray-6;

  span {
    color: $color-primary;
  }
}
.calendar-add-button:not(:first-child) {
  height: 20px;
  line-height: 20px;
}

.visit-list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 300ms ease-out;
  background: $color-solid;
  border-radius: 8px;
  margin-bottom: 8px;
}
.visit-list-row:hover {
  background-color: $color-gray-hover;
}
.visit-list-row-check {
  //margin-left: auto;
}
.visit-list-row-checkbox {
  margin-right: 8px;
}
.visit-list-row-info {
  align-self: start;
  width: calc(100% - 32px - 10px - 10px);
  //max-width: 300px;
}

// SUBSCRIPTION

.crm-subscription-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 20px 0;
}
.crm-subscription-card {
  display: flex;
  cursor: pointer;
  padding: 10px;
}
.crm-subscription-card:hover {
  background-color: $color-gray-hover;
}
.crm-subscription-card-title {
  width: 30px;
  text-align: right;
  margin-right: 20px;
  font-weight: $weight-bold;
}
.crm-subscription-card-avatar {
  margin-right: 10px;
}
.crm-subscription-status {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
  column-gap: 10px;
}
.crm-subscription-card-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.crm-payment-line {
  display: flex;
  gap: 10px;
}
.crm-payment-line-date {
  white-space: nowrap;
}
.crm-payment-line-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Radio Color

.crm-radio-color {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 2px solid $color-white;
  cursor: pointer;
  background-color: #fff;
  padding: 2px;
}
.crm-radio-color:hover {
  border-color: $color-gray-6;
}
.crm-radio-color.active {
  border: 2px solid $color-primary;
}
.crm-radio-color-item {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.graph-preview-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .graph-preview-item {
    border-radius: 8px;
    cursor: pointer;
    outline: 1px solid $color-gray-6;
    transition: outline 0.3s ease-in-out;
    &__selected {
      outline: 1px solid $color-primary;
    }
    &:hover {
      outline: 1px solid $color-primary;
    }
    background-color: #C4C4C4;
    width: 80px;
    height: 80px;
    background-position: center;
    background-size: 100%;

    .graph-preview-item__checkbox {
      position: relative;
      top: 2px;
      left: 5px;
    }
  }
}

.chart-root {
  transform: scale(0.2);
  border: 1px solid $color-border;
}

.hidden-chart-root {
  position: absolute;
  top: -99999999px;
  left: -99999999px;
}

// REPORT
.crm-report-filter {
  display: flex;
  gap: 10px;
  padding: 4px;
  background-color: $color-solid;
  border-radius: 4px;
  margin-bottom: 5px;
}
.crm-report-date-range {
  width: 100%;
  max-width: 300px;
}
.crm-report-group {
  margin: 20px 0;
}
.crm-report-line {
  display: flex;
  align-items: center;
  height: 50px;
  background: $color-solid;
  border-radius: 3px;
  margin-top: 10px;
  padding: 8px;
  transition: 0.2s;

  &.caption {
    background: none;
    color: $color-white;

    &:hover {
      background: none;
    }
  }

  &:hover {
    background: $color-gray-hover;
  }

  .crm-report-count {
    width: inherit;
  }
}
.crm-report-line.inclose {
  margin-left: 30px;
}
.crm-report-count {
  width: 60px;
  margin-right: 10px;
  margin-left: auto;
  font-weight: $weight-bold;
  text-align: right;
  white-space: nowrap;
}
.crm-report-price {
  width: 60px;
  text-align: right;
  margin-right: 10px;
  white-space: nowrap;
}


// TASK

.crm-task-group {
  margin-top: 30px;
}
.crm-task-line {
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  gap: 10px;
  cursor: pointer;
  background: $color-solid;
  border-radius: 8px;
  transition: 0.2s;
  padding: 8px;
  margin-top: 10px;
}
.crm-task-line:hover {
  background-color: $color-gray-hover;
}
.crm-task-date {
  white-space: nowrap;
}
.crm-task-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 2;
}
.crm-task-pagination {
  margin-top: 10px;
}

.infinite_component {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}
