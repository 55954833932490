@import "../../../styles/global";

.line-3-8col {
  width: calc((100% - 70px) / 8 * 3 + 20px);
}
.line-6-8col {
  width: calc((100% - 70px) / 8 * 6 + 50px);
}
.line-2-8col {
  width: calc((100% - 70px) / 8 * 2 + 10px);
  text-align: center;
}

.visits-line {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  //align-content: flex-start;
  gap: 10px;
  padding: 10px 0;
  //transition: background-color 300ms ease-out;
}
.visit-mark {
  margin-right: 10px;
}