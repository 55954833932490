@import "../../styles/global";

.header {
  background-color: var(--color-primary);
  .logo {
    min-width: 320px;
    max-width: 700px;
    margin: 0 auto 24px auto;
    padding: 32px 10px;
    img {
      height: 48px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  .icon {
    //display: flex;
    font-size: 60px;
    line-height: 60px;
  }
  .text {
    font-size: var(--size-title);
    font-weight: var(--weight-bold);
  }
}

.content {
  //display: flex;
  //flex-direction: column;
  //gap: 16px;
  div {
    margin-bottom: 16px;
  }
  hr {
    border: 1px solid var(--color-border);
    margin-bottom: 24px;
  }
}

.crm-payment-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
}

.antd-public-tabs .ant-tabs-nav::before {
  border-color: $color-border;
}

.antd-public-tabs .ant-tabs-tab-btn {
  color: $color-gray-3;
}

.antd-public-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $color-primary;
}

.antd-public-tabs .ant-tabs-ink-bar {
  background: $color-primary;
}

.public-message {
  border: 1px solid $color-border;
  border-radius: 8px;
  padding: 10px;
}

.public-message A {
  color: $color-primary;
}

.public-message-file {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid $color-gray-2;
}

.filter {
  background-color: $color-solid;
  border-radius: 50px;
  padding: 5px;
}

.public-chart-root {
  transform: scale(0.3);
}

.public-graphs {
  .ant-carousel .slick-dots li button {
    background-color: $color-primary;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background-color: $color-primary;
  }
}


.line {
  display: flex;
  gap: 8px;
  .left {flex: 1}
  .right {}
}

.tgButton {
  background-color: #38B0E3;
  color: #FFFFFF;
}