// COLORS

$color-white: #FFFFFF;
$color-black: #000000;

$color-gray-1: #333333;
$color-gray-2: #4F4F4F;
$color-gray-3: #828282;
$color-gray-4: #BDBDBD;
$color-gray-5: #E0E0E0;
$color-gray-6: #F2F2F2;
$color-gray-hover: #F0F0F0;

$color-primary: #2D68C4;
$color-text: $color-black;
$color-solid: #F8F8F8;
$color-caption: $color-gray-4;
$color-border: $color-gray-5;

$color-success: #70C140;
$color-warning: #FF7A00;
$color-danger: #FF4D4F;

$size-body: 16px;
$size-caption: 12px;
$size-title: 20px;

:root {
  --color-primary: #2D68C4;
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-solid: #F8F8F8;
  --color-solid-hove: #F0F0F0;
  --color-border: #E0E0E0;

  --size-caption: 12px;
  --size-title: 20px;

  --weight-bold: 700;
}