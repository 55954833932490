* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: none;
    color: inherit;
    text-decoration: none;
}

input,
button,
textarea,
select {
    font: inherit;
}