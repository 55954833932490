.crm-avatar {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  .text-wrapper {
    width: calc(100% - 50px);

    .childInfo_card {
      text-overflow: ellipsis;
    }

    .text {
      white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }
    .caption {
      margin-top: 4px;
    }

  }

}