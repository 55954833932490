@import '../../styles/global';

$fixedWidth: 144px;
$fixedHeight: 40px;

.bonusLayout {
  width: 100%;
  height: 44px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: $color-primary;
  color: $color-white;
  border-radius: 8px;
}

.bonusLayoutContent {
  width: calc(100% - 10px - 144px);
  display: flex;
  align-items: center;
}

.bonusLayoutFixed {
  width: $fixedWidth;
}

// Logo
.bonusLogo {
  display: flex;
  margin: 0 16px;
}
.bonusLogoImg {
  width: 109px;
  height: 16px;
}


// Value
.bonusValue {
  width: $fixedWidth;
  height: $fixedHeight;
  display: flex;
  justify-content: center;
  align-items: center;
}


// Vidget
.bonusVidget {
  width: $fixedWidth;
  height: $fixedHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bonusVidgetMinus {
  margin-left: 10px;
  button {
    color: var(--color-white);
  }
}

.bonusVidgetPlus {
  margin-right: 10px;
  button {
    color: var(--color-white);
  }
}

.bonusVidgetValueUpdated {
  opacity: 0.5;
  animation-duration: 2s;
  animation-name: pulsating;
  animation-iteration-count: infinite;
}

@keyframes pulsating {
  from {
    opacity: 0.5;
  }

  50% {
    opacity: 0.2;
  }

  to {
    opacity: 0.5;
  }
}
