.banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 300px;
  border-radius: 16px;

  background-color: #f0f0f0;
  background-size: cover;
  background-position: center;

  padding: 32px;

  @media screen and (max-width: 560px) {
    height: 260px;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .text {
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    width: 239px;
  }

  .button {
    width: 135px;
  }

}