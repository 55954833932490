.list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .row {
    border-radius: 8px;
    padding: 8px;
    background-color: var(--color-solid);
  }

}